import { default as indexBTAQbDAQYQMeta } from "/Users/codetheorist/FeCaffe/pages/index.vue?macro=true";
import { default as indexlzfICX2If8Meta } from "/Users/codetheorist/FeCaffe/pages/menu/index.vue?macro=true";
export default [
  {
    name: indexBTAQbDAQYQMeta?.name ?? "index___en",
    path: indexBTAQbDAQYQMeta?.path ?? "/",
    meta: indexBTAQbDAQYQMeta || {},
    alias: indexBTAQbDAQYQMeta?.alias || [],
    redirect: indexBTAQbDAQYQMeta?.redirect,
    component: () => import("/Users/codetheorist/FeCaffe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlzfICX2If8Meta?.name ?? "menu___en",
    path: indexlzfICX2If8Meta?.path ?? "/menu",
    meta: indexlzfICX2If8Meta || {},
    alias: indexlzfICX2If8Meta?.alias || [],
    redirect: indexlzfICX2If8Meta?.redirect,
    component: () => import("/Users/codetheorist/FeCaffe/pages/menu/index.vue").then(m => m.default || m)
  }
]